export default class WpGalleryToList {

    #gallery = null;
    #item = null;

    constructor(
        galleryDef = {
            selector: '.wp-block-columns',
            role: 'list'
        },
        itemDef = {
            selector: '.wp-block-column',
            role: 'listitem'
        }) {

        this.#gallery = galleryDef;
        this.#item = itemDef;

        $(document).ready(() => {
            this.setRolesOnGalleries(this.getGalleries());
        });
    }

    getGalleries() {
        let galleries = [];
        const wpGalleries = jQuery(this.#gallery.selector);
        if (wpGalleries.length > 0) {
            galleries = jQuery(wpGalleries).toArray()
        }
        return galleries;
    }

    setRole(wpGallery) {
        const object = jQuery(wpGallery);
        object.attr("role", this.#gallery.role);
        object.find(this.#item.selector).attr("role", this.#item.role);
    }

    setRolesOnGalleries(wpGalleries = []) {
        if (wpGalleries.length > 0) {
            wpGalleries.forEach(wpGallery => {
                this.setRole(wpGallery);
            });
        }
    }
}