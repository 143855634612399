import ReturnTop from './jAko/btn/ReturnTop.js';
import TogglerMenu from './jAko/TogglerMenu.js';
import OnScroll from './jAko/OnScroll.js';
import WpGalleryToList from './jAko/WpGalleryToList.js';

new ReturnTop(document.querySelector("#btn-top-return"), "d-none", 90);
new TogglerMenu("#main-header .navbar-toggler");
new OnScroll(jQuery("body"));

// au chargement des pages lancer la fonction
new WpGalleryToList(
    {
        selector: '.wp-block-columns',
        role: 'list'
    },
    {
        selector: '.wp-block-column',
        role: 'listitem'
    }
);

document.addEventListener('DOMContentLoaded', function () {
    var mobileMenu = document.getElementById('main-menu');
    var btnBurger = document.getElementById('btn-burger-mobile');
    console.log(btnBurger);
    if (mobileMenu) {
        mobileMenu.addEventListener('keydown', function (event) {
            if (event.key === 'Tab') {
                // Récupérez tous les éléments focusables dans le menu
                var focusableElements = mobileMenu.querySelectorAll('a, button, [tabindex="0"]');

                // Convertissez NodeList en tableau
                var focusableElementsArray = Array.prototype.slice.call(focusableElements);

                // Récupérez l'élément actuellement focusé
                var focusedElement = document.activeElement;

                // Trouvez l'index de l'élément actuellement focusé
                var focusedIndex = focusableElementsArray.indexOf(focusedElement);

                if (event.shiftKey) {
                    // Si Maj est enfoncé, passez au précédent
                    if (focusedIndex === 0) {
                        // Si le premier élément est focusé, passez au dernier
                        focusableElementsArray[focusableElementsArray.length - 1].focus();
                        event.preventDefault();
                    }
                } else {
                    // Si Maj n'est pas enfoncé, passez au suivant
                    if (focusedIndex === focusableElementsArray.length - 1) {
                        // Si le dernier élément est focusé, passez au bouton burger
                        btnBurger.focus();
                        event.preventDefault();
                    }
                }
            }
        });
    }
});
